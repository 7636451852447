export default {
  namespaced: true,
  state: {
    pagamentoSucesso: false,
  },
  mutations: {
    SET_PAYMENT_SUCCESSS(state, pagamento) {
      state.pagamentoSucesso = pagamento;
    },
  },
};
