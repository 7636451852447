export default {
  namespaced: true,
  state: {
    categorias: [],
    categorias_parents: [],
    categorias_last_page: 0,
  },
  mutations: {
    SET_CATEGORIAS(state, data) {
      state.categorias = data.items && data.items ? data.items : [];
      state.categorias_last_page = data.items.last_page;
    },
    RESET_CATEGORIAS(state) {
      state.categorias = [];
    },
    SET_CATEGORIAS_PARENTS(state, data) {
      state.categorias_parents = data && data ? data : [];
    },
    RESET_CATEGORIAS_PARENTS(state) {
      state.categorias_parents = [];
    },
  },
};
