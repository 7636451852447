import Vue from "vue";
import Vuelidate from "vuelidate";
import JsonExcel from "vue-json-excel";
import Paginate from "vuejs-paginate";
import JsonCSV from "vue-json-csv";
import { VueMaskDirective } from "v-mask";
import { BootstrapVue, ModalPlugin, TablePlugin, SpinnerPlugin, VBTooltipPlugin } from "bootstrap-vue";
import VueMeta from "vue-meta";
import vSelect from 'vue-select';
import Multiselect from 'vue-multiselect';


// General
Vue.directive("mask", VueMaskDirective);
Vue.use(Vuelidate);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.component("downloadExcel", JsonExcel);
Vue.component("downloadCsv", JsonCSV);
Vue.component("paginate", Paginate);
Vue.component('v-select', vSelect);
Vue.component('multiselect', Multiselect);

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(ModalPlugin);
Vue.use(TablePlugin);
Vue.use(SpinnerPlugin);
Vue.use(VBTooltipPlugin);

// Force CSS import
import 'vue-select/dist/vue-select.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';