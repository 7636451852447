export default {
  namespaced: true,
  state: {
    accounts: [],
    showAccounts: true,
    accountsPermissions: [],
    accountsLinked: [],
    invitationPending: [],
    invitationValidation: {},
    invitationAccept: false,
    accountActive: "",
    accounts_last_page: 0,
  },
  mutations: {
    SET_ACCOUNT_ACTIVE(state, data) {
      state.accountActive = data;
    },
    RESET_ACCOUNT_ACTIVE(state) {
      state.accountActive = null;
    },
    SET_INVITATION_ACCEPT(state, data) {
      state.invitationAccept = data;
    },
    SET_INVITATION_VALIDATION(state, data) {
      state.invitationValidation = data;
    },
    SET_SHOW_ACCOUNTS(state, data) {
      state.showAccounts = data;
    },
    SET_ACCOUNTS_LINKED(state, data) {
      state.accountsLinked = data;
    },
    SET_INVITATION_PENDING(state, data) {
      state.invitationPending = data;
    },
    SET_ACCOUNTS_PERMISSIONS(state, data) {
      state.accountsPermissions = data;
    },
    RESET_ACCOUNTS_PERMISSIONS(state) {
      state.accountsPermissions = [];
    },
    SET_ACCOUNTS(state, data) {
      state.accounts = data;
      state.accounts_last_page = data.items.last_page;
    },
    RESET_ACCOUNTS(state) {
      state.accounts = [];
    },
  },
};
