<template>
  <header id="header">
    <div class="header-img">
      <router-link to="/">
        <transition mode="out-in" name="fade-left">
          <component :is="logoEmpresa" />
        </transition>
      </router-link>
    </div>

    <div class="box-header" :class="{ active: getMenu }">
      <div class="header-submenu">
        <Submenu />
      </div>
      <div class="box-buttons">
        <div class="account-header" v-if="accounts.length > 1 && showAccounts">
          <div class="account-header__selected">
            <div>Visualizando conta</div>
            <div v-for="account in accounts" :key="account.id">
              <span v-if="account.selected">{{ account.name }}</span>
            </div>
          </div>
          <span class="icon-down" v-if="accounts.length > 1">
            <IconDownSmall></IconDownSmall>
          </span>
          <div class="account-header__nav" v-if="accounts.length > 1">
            <ul>
              <li v-for="account in accounts" :key="account.id">
                <span v-if="!account.selected" @click="setAccountActive(account.id)">{{ account.name }}</span>
              </li>
            </ul>
          </div> 
        </div>
        <!-- <div class="account-header" v-else>
          <div class="account-header__selected">
            <div>Visualizando conta</div>
            <div>
              <span>Carregando</span>
            </div>
          </div>
        </div> -->

        <div class="user-header" v-if="perfil">
          <div class="description_user">Usuário</div>
          <div class="box-user">
            <div>{{ perfil.name }}</div>
            <div v-if="perfil.company_data">{{ perfil.company_data.name }}</div>
          </div>
          <span class="icon-down">
            <IcomDown></IcomDown>
          </span>
          <div class="user-header__nav">
            <ul>
              <li v-for="menu in links" :key="menu.topic">
                <router-link :to="menu.url" v-if="menu.role">{{ menu.topic }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="btn-header">
          <div class="header-user">
            <a @click="deslogar">
              <svg
                data-name="Interface-Essential / Login/Logout / logout-2"
                xmlns="http://www.w3.org/2000/svg"
                width="29.992"
                height="30.013"
              >
                <g fill="#2c2c2c">
                  <path
                    d="M20.634 24.386a1.25 1.25 0 012.5 0v2.5a1.251 1.251 0 01-1.25 1.251h-9.379v1.251a.625.625 0 01-.234.488.633.633 0 01-.526.125L.492 27.5a.626.626 0 01-.49-.612V3.128a.626.626 0 01.49-.61l11.255-2.5a.625.625 0 01.76.61v1.251h9.379a1.251 1.251 0 011.25 1.25v3.126a1.25 1.25 0 11-2.5 0V5a.626.626 0 00-.625-.625h-6.878a.626.626 0 00-.625.625v20.009a.626.626 0 00.625.625h6.878a.626.626 0 00.625-.625zM7.503 17.508a1.876 1.876 0 10-1.876-1.876 1.876 1.876 0 001.876 1.876z"
                    fill-rule="evenodd"
                  />
                  <path
                    d="M29.435 14.591l-6.1-4.064a1.251 1.251 0 00-1.943 1.041v2.188h-5.159a1.876 1.876 0 000 3.752h5.159v2.188a1.251 1.251 0 001.943 1.04l6.1-4.064a1.251 1.251 0 000-2.081z"
                  />
                </g>
              </svg>
              Sair
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-hamburguer" @click="openMenu">
      <img src="~@/assets/images/icones/btn-hamburguer.svg" />
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import LogoPositivo from "@/components/logos/LogoPositivo.vue";
import LogoNegativo from "@/components/logos/LogoNegativo.vue";
import Submenu from "@/components/submenu/Submenu.vue";
import IcomDown from "@/components/icones/IcomDown.vue";
import IconDownSmall from "@/components/icones/IconDownSmall.vue";
import { updateOnesignalId } from "@/api/profile";
import store from "@/store";
import { getAccountsPermissions, setAccountActive } from "@/api/accounts";

export default {
  components: {
    LogoPositivo,
    LogoNegativo,
    Submenu,
    IcomDown,
    IconDownSmall,
  },
  data() {
    return {
      intervalId: null,
      modoLayout: false,
      gerenciarIntegracao: "",
    };
  },
  computed: {
    ...mapState({
      showAccounts: (state) => state.accounts.showAccounts,
      accounts: (state) => state.accounts.accountsPermissions,
      perfil: (state) => state.user.dadosCompletos,
      managerIntegration: (state) => state.user.gerenciarIntegracao,
      managerUser: (state) => state.user.gerenciarUsuario,
      perfilUsuario: (state) => state.user.dadosCompletos,
      getMenu: (state) => state.interacoes.menu,
    }),
    corLayout() {
      return this.modoLayout == false ? "layout-claro" : "layout-escuro";
    },
    logoEmpresa() {
      return this.modoLayout == false ? "LogoNegativo" : "LogoNegativo";
    },
    links() {
      let links = [
        {
          topic: "Minha conta",
          url: "/settings",
          role: true,
        },
        // {
        //   topic: "Admin",
        //   url: "/admin/companies",
        //   role: this.perfilUsuario.role == 2,
        // },
        {
          topic: "Integrações",
          url: "/settings/integrations",
          role: this.managerIntegration == 1,
        },
        {
          topic: "Plano",
          url: "/settings/plan",
          role: this.managerIntegration == 1,
        },
        {
          topic: "Usuários",
          url: "/settings/users",
          role: this.managerUser == 1,
        },
        {
          topic: "Contas vinculadas",
          url: "/settings/accounts-merged",
          role: this.managerIntegration == 1,
        },
        {
          topic: "Importações",
          url: "/settings/imports",
          role: this.managerIntegration == 1,
        },
      ];
      return links;
    },
  },
  methods: {
    setAccountActive,
    getAccountsPermissions,
    startInterval() {
      // Check if interval is not already running
      if (!this.intervalId) {
        // Set interval to update message every second
        this.intervalId = setInterval(() => {
          this.getAccountsPermissions();
        }, 30000);
      }
    },
    stopInterval() {
      // Check if interval is running
      if (this.intervalId) {
        // Clear the interval
        clearInterval(this.intervalId);
        this.intervalId = null; // Reset interval id
      }
    },
    deslogar() {
      this.$store.commit("user/SET_LOGADO", false);
      this.$store.commit("user/SET_MANAGER_INTEGRATION", "");
      this.$store.commit("user/SET_MANAGER_USER", "");
      this.$store.commit("interacoes/SET_PREVIOUS_PAGE", this.$route.path);
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("vtexAccount");
      this.$router.push("/login");
    },
    toggleFullscreen() {
      var elem = document.getElementById("container-fullscreen");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    setarLayout() {
      const modoLayout = window.localStorage.getItem("corLayout");
      if (modoLayout) {
        if (modoLayout == "layout-claro") {
          this.modoLayout = false;
          return this.$store.commit("interacoes/SET_TEMA", modoLayout);
        } else {
          this.modoLayout = true;
          return this.$store.commit("interacoes/SET_TEMA", modoLayout);
        }
      }
    },
    openMenu() {
      store.commit("interacoes/SET_MENU");
    },
  },
  unmounted() {
    this.stopInterval();
  },
  mounted() {
    this.getAccountsPermissions();
    this.$store.commit("interacoes/SET_SIDEBAR", false);
    this.setarLayout();
    this.stopInterval();
  },
  beforeCreate() {
    var onesignal = this.$OneSignal;

    onesignal.showSlidedownPrompt();
    onesignal.on("subscriptionChange", function(isSubscribed) {
      if (isSubscribed === true) {
        onesignal.getUserId((userId) => updateOnesignalId(userId));
      }
    });
  },
  watch: {
    corLayout() {
      window.localStorage.setItem("corLayout", this.corLayout);
      return this.$store.commit("interacoes/SET_TEMA", this.corLayout);
    },
    managerIntegration(data) {
      if (data) this.gerenciarIntegracao = data;
    },
    perfilUsuario(data) {
      if (data.role == 2) {
        this.startInterval();
      }
    }
  },
};
</script>

<style lang="scss">
#header {
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 90px;
  justify-content: space-between;
  position: relative;
  transition: 0.3s;
  width: 100%;
  z-index: 10;
  .btn-hamburguer {
    display: none;
  }
  &:after {
    background: rgb(240, 122, 122);
    background: linear-gradient(to right, rgba(240, 122, 122, 1) 0%, rgba(255, 46, 99, 1) 100%);
    bottom: -4px;
    content: " ";
    display: block;
    height: 4px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .header-submenu {
    display: flex;
    height: 100%;
  }
  .box-buttons {
    display: flex;
  }
  .header-img {
    position: relative;
    box-sizing: border-box;
    width: auto;
    height: 100%;
    padding: 5px 15px;
    border-right: 1px solid #e6e6e6;
    a {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      width: 100%;
    }
    img,
    svg {
      height: auto;
      max-height: 100%;
      max-width: 100px;
      pointer-events: none;
    }
  }
  .box-header {
    display: flex;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 700px) {
    height: 55px;
    .header-img {
      width: 220px;
      img,
      svg {
        max-width: 180px;
      }
    }
  }
  @media (max-width: 380px) {
    .header-img {
      width: 190px;
      img,
      svg {
        max-width: 150px;
      }
    }
  }
}
.btn-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  transition: 0.3s;
  @media (max-width: 340px) {
    padding-left: 20px;
    background: none !important;
  }
}
.user-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 130px;
  &:hover {
    .user-header__nav {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: all;
    }
  }
  .icon-down {
    padding-top: 10px;
    svg {
      width: 40px;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  &__nav {
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-40px);
    transition: all 100ms;
    position: absolute;
    top: 100%;
    width: 100%;
    min-width: 220px;
    z-index: 2;
    margin-bottom: 0;
    ul {
      margin-bottom: 0;
    }
    li {
      a {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        color: $cinza-escuro;
        display: block;
        font-weight: normal;
        padding: 15px 20px;
        text-decoration: none;
        transition: all 100ms;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
  .description_user {
    display: none;
  }
  .box-user {
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
    transition: 0.3s;
    width: 100%;

    > div {
      line-height: 1.3;

      &:first-child {
        color: darken($cinza-escuro, 40%);
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        @include for-tablet-landscape-down {
          text-align: left;
        }
      }
      &:last-child {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

.account-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #d6d6d6;
  padding: 0 3px 0 18px;
  min-width: 261px;
  &:hover {
    .account-header__nav {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: all;
    }
  }

  &__selected {
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
    transition: 0.3s;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: $dark;
    > div {
      line-height: 1.3;
      width: 100%;
      &:last-child {
        font-size: 14px;
        font-weight: 600;
        color: $dark;
      }
      &:first-child {
        color: #9b9b9b;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        @include for-tablet-landscape-down {
          text-align: left;
        }
      }
    }
  }
  &__nav {
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-40px);
    transition: all 100ms;
    position: absolute;
    top: calc(100%);
    width: 100%;
    z-index: 2;
    left: 0;
    margin-bottom: 0;
    ul {
      margin-bottom: 0;
    }
    li {
      height: max-content;
      padding: 0;
      span {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        color: $cinza-escuro;
        display: block;
        font-weight: normal;
        padding: 15px 20px;
        text-decoration: none;
        transition: all 100ms;
        background: $white;
        height: 55px;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
  .description_user {
    display: none;
  }
  .icon-down {
    padding-top: 10px;
    svg {
      width: 40px;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
}

.header-layout {
  .description_tema {
    display: none;
  }
  input {
    display: none;
  }
  .toggleLayout {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 60px;
    height: 35px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid $cinza-interno;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    &:after {
      position: absolute;
      top: 50%;
      left: 5px;
      box-sizing: border-box;
      width: 25px;
      height: 25px;
      content: "";
      transition: 0.3s;
      transform: translateY(-50%);
      border-radius: 50%;
      background: $elementos-internos url("~@/assets/images/icones/layout-claro.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 17px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    &.active:after {
      left: calc(100% - 30px);
      background: lighten($elementos-internos, 25%) url("~@/assets/images/icones/layout-escuro.svg");
      background-repeat: no-repeat;
      background-position: 6px 4px;
      background-size: 12px;
    }
  }
}
.header-user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 40px 0 30px;
  a {
    align-items: center;
    color: $cor-escura !important;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      svg {
        transform: scale(0.8) translate(5px, 0);
      }
    }
    svg {
      transform: scale(0.8);
      transition: 0.2s;
    }
  }
  @media (max-width: 700px) {
    margin: 0 20px 0 15px;
  }
}

@media (max-width: 991px) {
  #header {
    height: 80px;
    .header-img {
      max-width: 150px;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        img,
        svg {
          max-width: 100px;
        }
      }
    }
    .box-header {
      position: fixed;
      top: 0;
      flex-direction: column;
      width: 100%;
      z-index: 10;
      height: 100%;
      opacity: 0;
      transition: all 350ms ease-in-out;
      pointer-events: none;
      min-height: 100%;
      overflow: scroll;
      &.active {
        opacity: 1;
        pointer-events: all;
      }
      .header-submenu {
        height: auto;
        width: 100%;
        .container-submenu {
          width: 100%;
          .link-sidebar {
            height: 80px;
            width: 100%;
            &.active {
              &::before {
                width: 80px;
                background-color: #fd2e63;
              }
              .link-icone {
                width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  rect,
                  circle,
                  path {
                    fill: #fff;
                  }
                }
              }
            }
            .link-icone {
              width: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .link-texto {
              padding-left: 25px;
            }
          }
          .container-submenu__active {
            height: auto;
            &::after {
              content: "";
              height: 5px;
              background-color: #fd2e63;
              border: none;
              width: 100%;
              left: 0;
              bottom: 0;
              top: auto;
            }
          }
          .container-submenu__items {
            position: relative;
            display: flex;
            top: auto;
            flex-direction: column;
            box-shadow: none;
            .active {
              display: none;
            }
          }
        }
      }
      .box-buttons {
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: #f5f5f5;
        .user-header {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          background-color: #f5f5f5;
          width: 100%;
          flex: 1;
          max-height: 100px;
          .description_user {
            display: flex;
            flex: 1;
            color: #2c2c2c;
          }
          .box-user {
            padding: 0;
            flex: 1;
          }
        }
        .btn-header {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          background-color: #f5f5f5;
          width: 100%;
          flex: 1;
          flex-direction: column;
          padding-top: 0;
          .header-fullscreen {
            display: none;
          }
          .header-layout {
            display: flex;
            width: 100%;
            padding: 15px 0;
            .description_tema {
              display: flex;
              flex: 1;
            }
            .toogle_tema {
              display: flex;
              align-items: center;
              &::before {
                content: "";
                padding-right: 15px;
                color: #2c2c2c;
                font-weight: 500;
              }
              &.false {
                &::before {
                  content: "Claro";
                }
              }
              &.true {
                &::before {
                  content: "Escuro";
                }
              }
            }
          }
          .header-user {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 15px 0;
          }
        }
      }
    }
    .btn-hamburguer {
      z-index: 10;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      pointer-events: all;
    }
  }
}
</style>
