import axios from "axios";
import store from "@/store";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
});

client.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers.crossdomain = true;
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem:
          error.response.data && error.response.data.message
            ? error.response.data.message
            : "Erro inesperado. Tente novamente mais tarde...",
        tipo: "erro",
      });

      if (error.response.status === 401) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("usuario_perfil");
        window.localStorage.removeItem("usuario_id");
        store.commit("user/SET_LOGADO", false);
        location.reload();
      }
    } else {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Não foi possível conectar ao servidor",
        tipo: "erro",
      });
    }
    return Promise.reject(error);
  }
);

export default client;
