export default {
  namespaced: true,
  state: {
    produtos: [],
    produtos_last_page: 0,
    produtos_per_page: 0,
  },
  mutations: {
    SET_PRODUTOS(state, data) {
      state.produtos = data.items && data.items ? data.items : [];
      state.produtos_last_page = data.items.last_page;
      state.produtos_per_page = data.items.per_page;
    },
    RESET_PRODUTOS(state) {
      state.produtos = [];
    },
  },
};
