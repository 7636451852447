import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/plugins/_conf";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import OneSignalVue from 'onesignal-vue';
import Clipboard from "v-clipboard";

Vue.config.productionTip = false;
Vue.use(OneSignalVue);
Vue.use(Clipboard);

new Vue({
  router,
  store,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: process.env.VUE_APP_ONESIGNAL_ID });
  }
}).$mount("#app");

document.title = process.env.VUE_APP_TITLE;