export default {
  namespaced: true,
  state: {
    media: [],
    media_last_page: 0,
  },
  mutations: {
    SET_MEDIA(state, data) {
      state.media = data.items && data.items ? data.items : [];
      state.media_last_page = data.items.last_page;
    },
    RESET_MEDIA(state) {
      state.media = [];
    },
  },
};
