export default {
  namespaced: true,
  state: {
    estaLogado: false,
    perfilUsuario: "",
    idUsuario: "",
    dadosCompletos: null,
    keys: {},
    gerenciarIntegracao: "", 
    gerenciarUsuario: "",
  },
  mutations: {
    SET_LOGADO(state, logado) {
      state.estaLogado = logado;
    },
    SET_PERFIL(state, perfil) {
      state.perfilUsuario = perfil;
    },
    SET_ID_USUARIO(state, id) {
      state.idUsuario = id;
    },
    SET_DADOS_COMPLETOS(state, data) {
      state.dadosCompletos = data;
    },
    SET_KEYS(state, keys) {
      state.keys = keys;
    },
    SET_MANAGER_INTEGRATION(state, data) {
      state.gerenciarIntegracao = data;
    },
    SET_MANAGER_USER(state, data) {
      state.gerenciarUsuario = data;
    },
    
  },
};
