<template>
  <div>
    <CompHeader />
    <div class="container-interna" :class="{ desactive: !menuAtivo }">
      <transition mode="out-in" name="fade">
        <router-view class="container-conteudo" id="container-fullscreen"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CompHeader from "@/components/header/Header.vue";
import { buscarPerfil } from "@/api/login";
import { VerifyTrial } from "@/api/plano";
export default {
  name: "interna",
  components: {
    CompHeader,
  },
  computed: {
    menuAtivo() {
      return this.$store.state.interacoes.menuAtivado;
    },
    ...mapState({
      verifyTrial: state => state.plano.verifyTrial,
    }),
  },
  methods: {
    checkRole() {
      const roleIntegration = ["integrations", "plan", "import"];
      const roleUser = ["users"];
      let setRoleIntegration = roleIntegration.some(e => e.includes(this.$route.name)) && this.gerenciarIntegracao == 0;
      let setRoleUser = roleUser.some(e => e.includes(this.$route.name)) && this.managerUser == 0;
      if (this.verifyTrial.has_plan || (!this.verifyTrial.expired && this.verifyTrial.is_7_days_trial)) {
        if (setRoleIntegration || setRoleUser) {
          return this.$router.push("/");
        }
      }
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      VerifyTrial();
      buscarPerfil();
      this.checkRole();
    }
  },
  watch: {
    $route(to) {
      if (to) {
        this.checkRole(to.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-interna {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - 90px);
  .container-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 250px;
    height: 100%;
    transition: 0.3s;
    overflow-x: hidden;
    z-index: 10;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  &.desactive {
    .container-sidebar {
      width: 60px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }
    .container-conteudo {
      width: 100%;
      padding-bottom: 35px;
    }
  }
  @media (max-width: 700px) {
    height: calc(100vh - 55px);
    .container-sidebar {
      z-index: 90;
      width: 250px;
    }
    &.desactive {
      .container-sidebar {
        width: 60px;
      }
    }
  }
}
</style>
