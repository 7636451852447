import VueGtm from "@gtm-support/vue2-gtm";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import { verificaSessao } from "@/api/login";

// componentes do bundle
import LayoutLogado from "@/views/_LayoutLogado.vue";

// Exporta em arquivo à parte e carrega a View somente quando é solicitado
function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: LayoutLogado,
    children: [
      {
        path: "/",
        name: "inicio",
        component: lazyLoad("Dashboard"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Dashboard",
            icone: "IconInicio",
          },
        },
      },
      {
        path: "/orders",
        name: "orders",
        component: lazyLoad("Orders"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Pedidos",
            icone: "IconOrders",
          },
        },
      },
      {
        path: "/customers",
        name: "customers",
        component: lazyLoad("Customers"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            // highlight: "Novo",
            texto: "CRM",
            icone: "IconCustomers",
          },
        },
      },
      {
        path: "/customers/list",
        name: "customers",
        component: lazyLoad("CustomersAll"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            onlyTitle: true,
            texto: "CRM",
            icone: "IconCustomers",
          },
        },
      },
      {
        path: "/customers/:id/details",
        name: "customers",
        component: lazyLoad("CustomersDetails"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            onlyTitle: true,
            texto: "CRM",
            icone: "IconCustomers",
          },
        },
      },
      {
        path: "/categories",
        name: "categories",
        component: lazyLoad("Categorias"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Departamentos",
            icone: "IconCategories",
          },
        },
      },
      {
        path: "/products",
        name: "products",
        component: lazyLoad("Produtos"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Catálogo",
            icone: "IconProducts",
          },
        },
      },
      {
        path: "/brands",
        name: "brands",
        component: lazyLoad("Marcas"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Marcas",
            icone: "IconBrands",
          },
        },
      },
      {
        path: "/affiliate",
        name: "afiliados",
        component: lazyLoad("Affiliate"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Marketplace Out",
            icone: "IconAffiliate",
          },
        },
      },
      {
        path: "/sellers",
        name: "sellers",
        component: lazyLoad("Sellers"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Marketplace In",
            icone: "IconSellers",
          },
        },
      },
      {
        path: "/media-source",
        name: "media",
        component: lazyLoad("Media"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Marketing",
            icone: "IconMedia",
          },
        },
      },
      {
        path: "/contas",
        name: "Accounts",
        component: lazyLoad("Accounts"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: {
            texto: "Contas",
            icone: "IconAccountsMerged",
          },
        },
      },
      {
        path: "/account-invite/:token",
        name: "invitation",
        component: lazyLoad("Invitation"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: false,
          emptyIcon: true,
          accounts: false,
        },
      },
      {
        path: "/settings",
        name: "settings",
        component: lazyLoad("Settings"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: false,
          emptyIcon: true,
        },
      },
      {
        path: "/settings/integrations",
        name: "integrations",
        component: lazyLoad("Integration"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: false,
          emptyIcon: true,
        },
      },
      {
        path: "/settings/plan",
        name: "plan",
        component: lazyLoad("Plan"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: false,
          emptyIcon: true,
        },
      },
      {
        path: "/settings/imports",
        name: "import",
        component: lazyLoad("Import"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: false,
          emptyIcon: true,
        },
      },
      {
        path: "/settings/users",
        name: "users",
        component: lazyLoad("Users"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: false,
          emptyIcon: true,
        },
      },
      {
        path: "/settings/accounts-merged",
        name: "AccountsMerged",
        component: lazyLoad("AccountsMerged"),
        meta: {
          precisaAutenticacao: true,
          menuInterno: false,
          emptyIcon: true,
        },
      },

      // Novas rotas Admin
      // {
      //   path: "/admin/companies",
      //   name: "admin.companies",
      //   component: lazyLoad("Admin/Companies"),
      //   meta: {
      //     precisaAutenticacao: true,
      //     menuInterno: false,
      //     emptyIcon: true,
      //   },
      // },
      // {
      //   path: "/admin/companies/orders",
      //   name: "admin.companies.orders",
      //   component: lazyLoad("Admin/CompaniesOrders"),
      //   meta: {
      //     precisaAutenticacao: true,
      //     menuInterno: false,
      //     emptyIcon: true,
      //   },
      // },
      // {
      //   path: "/admin/users",
      //   name: "admin.users",
      //   component: lazyLoad("Admin/Users"),
      //   meta: {
      //     precisaAutenticacao: true,
      //     menuInterno: false,
      //     emptyIcon: true,
      //   },
      // },
      // {
      //   path: "/admin/invites",
      //   name: "admin.invites",
      //   component: lazyLoad("Admin/Invites"),
      //   meta: {
      //     precisaAutenticacao: true,
      //     menuInterno: false,
      //     emptyIcon: true,
      //   },
      // },
      // {
      //   path: "/admin/vtex-accounts",
      //   name: "admin.vtex.accounts",
      //   component: lazyLoad("Admin/VtexAccounts"),
      //   meta: {
      //     precisaAutenticacao: true,
      //     menuInterno: false,
      //     emptyIcon: true,
      //   },
      // },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: lazyLoad("Login"),
  },
  {
    path: "/signup/:token",
    name: "signup",
    component: lazyLoad("Signup"),
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: lazyLoad("Subscribe"),
  },
  {
    path: "/update-password/:token",
    name: "update-password",
    component: lazyLoad("RecuperarSenha"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

//GTM
Vue.use(VueGtm, {
  id: "GTM-T4GNGCG",
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: ["homepage"],
  trackOnNextTick: false,
});

router.beforeEach((to, from, next) => {
  // Verifica o Token e seta no Store
  verificaSessao();

  if (to.meta.precisaAutenticacao && !store.state.user.estaLogado) {
    store.commit("interacoes/SET_PREVIOUS_PAGE", to.path);
    return next("/login");
  }

  if (to.path == "/login" && store.state.user.estaLogado) {
    return next("/");
  }
  if (to.path !== "/invitation") {
    store.commit("accounts/SET_SHOW_ACCOUNTS", true);
  }

  if (store.state.interacoes.menuAtivado) {
    store.commit("interacoes/SET_SIDEBAR", false);
  }
  if (store.state.interacoes.modal.modalAtivado) {
    store.commit("interacoes/SET_MODAL", {
      ativado: false,
      mensagem: "",
      tipo: "",
    });
  }

  next();
});

router.afterEach((to) => {
  if (
    to.path != "/settings/plan" &&
    to.name != "invitation" &&
    store.state.plano.verifyTrial.expired &&
    !store.state.plano.verifyTrial.is_7_days_trial
  ) {
    router.push("/settings/plan");
  }
});

export default router;
