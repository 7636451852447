export default {
  namespaced: true,
  state: {
    vendasVtexFulfillmentPeriodo: [],
    vendasCanal: [],
    vendasCanalPorPagina: [],
    vendasCanalPorPagina_last_page: 0,
    affiliatesList: []
  },
  mutations: {
    SET_VENDAS_VTEX_FULFILLMENT_PERIODO(state, data) {
      state.vendasVtexFulfillmentPeriodo = data.items && data.items ? data.items : [];
    },
    RESET_VENDAS_VTEX_FULFILLMENT_PERIODO(state) {
      state.vendasVtexFulfillmentPeriodo = [];
    },
    SET_VENDAS_CANAL(state, data) {
      state.vendasCanal = data.items && data.items ? data.items : [];
      state.vendasCanalPorPagina_last_page = data.items.last_page;
    },
    RESET_VENDAS_CANAL(state) {
      state.vendasCanal = [];
    },
    SET_AFFILATE_LIST(state, data) {
      state.affiliatesList = data && data ? data : [];
    },
    RESET_AFFILATE_LIST(state) {
      state.affiliatesList = [];
    },
  },
};
