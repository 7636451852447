<template>
  <router-link v-if="show" class="link-sidebar" :to="link" exact @click.native="closeMenu">
    <span class="link-icone"><component :is="icone"/></span>
    <span class="link-texto">{{ texto }}</span>
    <span v-if="highlight" class="link-highlight">{{ highlight }}</span>
  </router-link>
</template>

<script>
import IconMenu from "@/components/icones/IconMenu.vue";
import IconInicio from "@/components/icones/IconInicio.vue";
import IconProducts from "@/components/icones/IconProducts.vue";
import IconConfiguracoes from "@/components/icones/IconConfiguracoes.vue";
import IconCategories from "@/components/icones/IconCategories.vue";
import IconBrands from "@/components/icones/IconBrands.vue";
import IconVtex from "@/components/icones/IconVtex.vue";
import IconSources from "@/components/icones/IconSources.vue";
import IconAffiliate from "@/components/icones/IconAffiliate.vue";
import IconMedia from "@/components/icones/IconMedia.vue";
import IconSellers from "@/components/icones/IconSellers.vue";
import IconAccountsMerged from "@/components/icones/IconAccountsMerged.vue";
import IconOrders from "@/components/icones/IconOrders.vue";
import IconCustomers from "@/components/icones/IconCustomers.vue";

import store from "@/store";

export default {
  props: ["show", "texto", "link", "icone", "highlight"],
  components: {
    IconMenu,
    IconInicio,
    IconProducts,
    IconConfiguracoes,
    IconCategories,
    IconBrands,
    IconVtex,
    IconSources,
    IconAffiliate,
    IconMedia,
    IconSellers,
    IconAccountsMerged,
    IconOrders,
    IconCustomers,
  },
  methods: {
    closeMenu() {
      store.commit("interacoes/SET_MENU");
    },
  },
};
</script>

<style lang="scss">
.link-sidebar {
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  font-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
  height: 55px;
  transition: 0.3s;
  text-align: center;
  text-decoration: none;
  color: $cor-escura;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 60px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    // border-radius: 0 18px 18px 0;
    // box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  &:after {
    position: absolute;
    top: 0;
    left: 65px;
    visibility: hidden;
    width: 190px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    // border-radius: 18px 0 0 18px;
  }
  &:hover {
    text-decoration: none;
    &:before {
      visibility: visible;
      opacity: 1;
      // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }
    .link-texto {
      padding-left: 10px;
    }
  }
  &.active {
    &:before {
      visibility: visible;
      opacity: 1;
      // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }
    &:after {
      visibility: visible;
      opacity: 1;
    }
    .link-texto {
      // padding-left: 20px;
    }
  }
  .link-icone {
    position: relative;
    z-index: 1;
    display: block;
    width: 60px;
    min-width: 60px;
    svg,
    img {
      display: block;
      max-width: 22px;
      height: auto;
      max-height: 40px;
      margin: 0 20px;
    }
    .elementos-internas {
      transition: 0.3s;
    }
  }
  .link-texto {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 190px;
    transition: 0.3s;
    text-align: left;
    color: $cor-escura;
  }
  .link-highlight {
    background: $light-blue;
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid darken($light-blue, 20%);
    color: $white;
    font-size: 13px;
    font-weight: 700;
    max-height: 35px;
    padding: 4px 10px;
    position: absolute;
    right: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.3s;
    width: auto;
    z-index: 1;
  }
}
</style>
