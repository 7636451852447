import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/user.js";
import vtex from "@/store/vtex.js";
import dashboard from "@/store/dashboard.js";
import interacoes from "@/store/interacoes.js";
import vendas from "@/store/vendas.js";
import categorias from "@/store/categorias.js";
import produtos from "@/store/produtos.js";
import marcas from "@/store/marcas.js";
import company from "@/store/company.js";
import plano from "@/store/plano.js";
import importacao from "@/store/importacao.js";
import pagamento from "@/store/pagamento.js";
import admin from "@/store/admin.js";
import midiaSource from "@/store/midiaSource.js";
import sellers from "@/store/sellers.js";
import accounts from "@/store/accounts.js";
import orders from "@/store/orders.js";
import filters from "@/store/filters.js";
import integrations from "@/store/integrations.js";
import customers from "@/store/customers.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    interacoes,
    vtex,
    importacao,
    dashboard,
    plano,
    vendas,
    categorias,
    produtos,
    marcas,
    company,
    pagamento,
    admin,
    midiaSource,
    sellers,
    accounts,
    orders,
    filters,
    integrations,
    customers,
  },
});
