// CAMINHOS API
export function _imagemMobile(img) {
  return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Comunicacao/ImagemMobile/${img}`;
}
export function _imagemDesktop(img) {
  return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Comunicacao/ImagemDesktop/${img}`;
}
export function _imagemCupom(img) {
  return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Upload/UploadCupom/${img}`;
}
//
// LOGIN
export function _rotaLogin() {
  return "/login";
}
export function _rotaPreCadastro() {
  return "/signup";
}
export function _rotaCadastroCompleto() {
  return "/signup/complete";
}
export function _rotaVerificarConvite() {
  return "/signup/invitation/check";
}
export function _rotaRecuperarSenha() {
  return "/password-reset";
}
export function _rotaProfile() {
  return "/players/profile/edit";
}
export function _rotaProfileOneSignal() {
  return "/players/profile/onesignal";
}
export function _rotaGetProfile() {
  return "/players/profile";
}
export function _rotaGetKeys() {
  return "/players/company/keys";
}
//
// VtexAccount
export function _rotaVtexAccount() {
  return "/vtex/vtex_account";
}
//
// Integrations
export function _rotaIntegrations() {
  return "/integrations";
}
//
// VtexProduct
export function _rotaVtexProduct() {
  return "/vtex/product";
}
export function _rotaCriaProdutoVtex() {
  return "/vtex/product/add";
}
//
// VtexSku
export function _rotaVtexSku() {
  return "/vtex/sku";
}
export function _rotaListaSkusVtex() {
  return "/vtex/sku/list";
}
export function _rotaCriaSkuVtex() {
  return "/vtex/sku/add";
}
//
// VtexDb
export function _rotaVtexDb() {
  return "/vtex/vtex_db/add";
}
//
// VtexLog
export function _rotaVtexLog() {
  return "/vtex/log";
}
//
// VtexJob
export function _rotaVtexJob() {
  return "/vtex/job";
}
export function _rotaGetVtexJobStatus() {
  return "/vtex/job/status";
}
//
// VtexDashboard
export function _rotaVtexDashboardPedidos() {
  return "/vtex/dashboard/pedidos";
}
export function _rotaVtexDashboardProdutos() {
  return "/vtex/dashboard/produtos";
}
export function _rotaVtexDashboardMarcas() {
  return "/vtex/dashboard/marcas";
}
//
// VtexAfiliados
export function _rotaVtexAfiliadosValor() {
  return "/vtex/affiliates";
}
export function _rotaVtexAfiliadosList() {
  return "/vtex/affiliates/list";
}
export function _rotaGetCSVAfiliados() {
  return "/vtex/affiliates/export";
}
export function _rotaVtexAfiliadosVtexFulfillmentPeriod() {
  return "/vtex/affiliates/vtex-fulfillment/period";
}

// Categoria
export function _rotaCategoriasMaisVendidas() {
  return "/vtex/categories";
}
export function _rotaGetCSVCategorias() {
  return "/vtex/categories/export";
}

export function _rotaCategoriasParents() {
  return "/vtex/dashboard/categorias/parents";
}

// Produtos

export function _rotaProdutos() {
  return "/vtex/products";
}
export function _rotaGetCSVProdutos() {
  return "/vtex/products/export";
}

// Marcas

export function _rotaMarcas() {
  return "/vtex/brands";
}
export function _rotaGetCSVMarcas() {
  return "/vtex/brands/export";
}

// Company

export function _rotaGetUsersMyCompany() {
  return "/players/company/users";
}

export function _rotaAddUsersMyCompany() {
  return "/players/company/users/add";
}

export function _rotaDelUsersMyCompany(id) {
  return `/players/company/users/remove/${id}`;
}

export function _rotaPutUsersMyCompany(id) {
  return `/players/company/users/edit/${id}`;
}

// Plans

export function _rotaGetActivePlan() {
  return "/plans/active";
}
export function _rotaGetPlansAvailable() {
  return "/plans/available";
}
export function _rotaGetPlansUnpaid() {
  return "/plans/unpaid";
}
export function _rotaGetPlans() {
  return "/plans";
}
export function _rotaVerifyTrial() {
  return "/plans/verify-trial";
}

// CSV

export function _rotaImportCSV() {
  return "/vtex/orders/import-csv";
}

// Payment

export function _rotaSetPagamento() {
  return "/payment";
}

// Admin

export function _rotasAdmin() {
  return "/admin";
}

// Media & Source

export function _rotaVtexType(type) {
  return `/vtex/marketing/data/utm${type}`;
}
export function _rotaMediaGetCSV(type) {
  return `/vtex/marketing/data/utm${type}/export`;
}

// Sellers

export function _rotaGetSellers() {
  return "/vtex/sellers";
}
export function _rotaGetSellersIds() {
  return "/vtex/sellers/list";
}
export function _rotaGetCSVSellers() {
  return "/vtex/sellers/export";
}

/// accounts
export function _rotaGetAccountsPermissions() {
  return `my-accounts`;
}
export function _rotaDeleteAccount(accountId) {
  return `account-permissions?permission=${accountId}`;
}
export function _rotaSetAccountActive(accountId) {
  return `my-accounts/active?account=${accountId}`;
}
export function _rotaGetAccounts() {
  return `my-accounts/data`;
}
export function _rotaGetCsvAccounts() {
  return `my-accounts/data/export`;
}

// linked accounts
export function _rotaGetAccountsLinked() {
  return `account-permissions`;
}
export function _rotaSendInvitation() {
  return `/account-permissions/invites`;
}
export function _rotaGetInvitationPending() {
  return `/account-permissions/invites`;
}
export function _rotaDeleteInvitation(id) {
  return `/account-permissions/invites?invite=${id}`;
}

// account-invite
export function _rotaValidationTokenInvitation(token) {
  return `/account-permissions/invites/${token}`;
}
export function _rotaAcceptInvite(token) {
  return `/account-permissions/invites/${token}/accept`;
}

//Orders

export function _rotaOrders() {
  return `vtex/orders`;
}
export function _rotaOrdersDetails() {
  return `vtex/orders/details`;
}
export function _rotaOrdersPayments() {
  return `vtex/orders/payments`;
}
export function _rotaOrdersStatus() {
  return `vtex/orders/statuses`;
}
export function _rotaOrdersCSV() {
  return `vtex/orders/details/export`;
}

//Customers

export function _rotaCustomers() {
  return `vtex/customers`;
}

export function _rotaCustomerDetails(customerId) {
  return `vtex/customers/customer/${customerId}`;
}

export function _rotaCustomerAddNote(customerId) {
  return `vtex/customers/customer/${customerId}/note`;
}

export function _rotaCustomerStar(customerId) {
  return `vtex/customers/customer/${customerId}/starred`;
}

export function _rotaCustomersOrdersTotal() {
  return `vtex/customers/orders/total`;
}

export function _rotaCustomersOrdersBilling() {
  return `vtex/customers/orders/billing`;
}

export function _rotaCustomersCoupons() {
  return `vtex/marketing/coupons`;
}

export function _rotaCustomersRecurrent() {
  return `vtex/customers/recurrent`;
}

export function _rotaCustomersTicketMedio() {
  return `vtex/customers/ticket-medio`;
}

export function _rotaCustomersRepurchase() {
  return `vtex/customers/repurchase`;
}

export function _rotaCustomersLtv() {
  return `vtex/customers/ltv`;
}

//Filters
export function _rotaGetFilterStatus() {
  return `vtex/orders/status/list`;
}
