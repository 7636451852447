export default {
  namespaced: true,
  state: {
    status: [],
  },
  mutations: {
    SET_STATUS_LIST(state, data) {
      state.status = data && data ? data : [];
    },
    RESET_STATUS_LIST(state) {
      state.status = [];
    },
  },
};
