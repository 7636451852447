import client from "@/api";
import store from "@/store";
import router from "@/router";
// Caminhos
import { _rotaGetActivePlan, _rotaGetPlansAvailable, _rotaGetPlansUnpaid, _rotaGetPlans, _rotaVerifyTrial } from "@/api/_caminhosApi";

export function GetActivePlan() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetActivePlan())
    .then(resp => {
      store.commit("plano/SET_PLANO_ATIVO", resp.data);
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function GetPlansAvailable() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetPlansAvailable())
    .then(resp => {
      store.commit("plano/SET_PLANO_DISPONIVEL", resp.data);
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function GetPlansUnpaid() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetPlansUnpaid())
    .then(resp => {
      store.commit("plano/SET_PLANOS_UNPAID", resp.data);
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function GetPlans() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetPlans())
    .then(resp => {
      store.commit("plano/SET_PLANO", resp.data);
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function VerifyTrial() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaVerifyTrial())
    .then(resp => {
      store.commit("pagamento/SET_PAYMENT_SUCCESSS", false);
      store.commit("plano/SET_VERIFY_TRIAL", resp.data);
      if (router.history.current.path != "/settings/plan" && router.history.current.name != "invitation") {
        if (resp.data.expired && !resp.data.is_7_days_trial) {
          router.push("/settings/plan");
        }
      }
    })
    .catch(erro => {
      store.commit("plano/SET_VERIFY_TRIAL", {});
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
