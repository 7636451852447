import client from "@/api";
import store from "@/store";
// Caminhos
import { _rotaProfile, _rotaProfileOneSignal, _rotaGetKeys } from "@/api/_caminhosApi";

export function UpdatePerfil(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .put(`${_rotaProfile()}`, data)
    .then((resp) => {
      store.commit("user/SET_DADOS_COMPLETOS", resp.data.data);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Perfil atualizado com sucesso",
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function updateOnesignalId(id) {
  client
    .put(`${_rotaProfileOneSignal()}`, { onesignal_id: id })
    .catch(erro => console.log("erro", erro.response));
}

export function getPerfilKeys() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetKeys())
    .then((resp) => {
      store.commit("user/SET_KEYS", resp.data);
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
