export default {
  namespaced: true,
  state: {
    account: [],
    accountSuccess: false,
    skus: [],
    skus_last_page: 0,
    skus_per_page: 0,

    products: [],
    products_last_page: 0,
    products_per_page: 0,
    products_total: 0,

    logs: [],
    logs_last_page: 0,
    logs_per_page: 0,

    jobs: [],
    jobs_last_page: 0,
    jobs_per_page: 0,
    job_status: null,
  },
  mutations: {
    SET_ACCOUNT_SUCCESSS(state, data) {
      state.accountSuccess = data;
    },
    SET_ACCOUNT(state, data) {
      state.account = data;
    },

    RESET_LOGS(state) {
      state.logs = [];
      state.logs_last_page = 0;
      state.logs_per_page = 0;
    },
    SET_LOGS(state, data) {
      state.logs = data.data;
      state.logs_last_page = data.last_page;
      state.logs_per_page = data.per_page;
    },

    RESET_JOBS(state) {
      state.jobs = [];
      state.jobs_last_page = 0;
      state.jobs_per_page = 0;
    },
    SET_JOBS(state, data) {
      state.jobs = data.data;
      state.jobs_last_page = data.last_page;
      state.jobs_per_page = data.per_page;
    },
    SET_JOB_STATUS(state, data) {
      state.job_status = data;
    },

    RESET_PRODUCTS(state) {
      state.products = [];
      state.products_last_page = 0;
      state.products_per_page = 0;
      state.products_total = 0;
    },
    SET_PRODUCTS(state, data) {
      state.products = data.data;
      state.products_last_page = data.last_page;
      state.products_per_page = data.per_page;
      state.products_total = data.total;
    },
  },
};
