export default {
  namespaced: true,
  state: {
    sellers: [],
    sellersList: [],
    sellers_last_page: 0,
  },
  mutations: {
    SET_SELLERS(state, data) {
      state.sellers = data.items && data.items ? data.items : [];
      state.sellers_last_page = data.items.last_page;
    },
    RESET_SELLERS(state) {
      state.sellers = [];
    },
    SET_SELLERS_LIST(state, data) {
      state.sellersList = data && data ? data : [];
    },
    RESET_SELLERS_LIST(state) {
      state.sellersList = [];
    },
  },
};
