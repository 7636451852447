export default {
  namespaced: true,
  state: {
    integrations: [],
    integrationsSuccess: false,
  },
  mutations: {
    SET_INTEGRATIONS_SUCCESSS(state, data) {
      state.integrationsSuccess = data;
    },
    SET_INTEGRATIONS(state, data) {
      state.integrations = data;
    },
  },
};
