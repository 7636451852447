export default {
  namespaced: true,
  state: {
    marcas: [],
    marcas_last_page: 0,
  },
  mutations: {
    SET_MARCAS(state, data) {
      state.marcas = data.items && data.items ? data.items : [];
      state.marcas_last_page = data.items.last_page;
    },
    RESET_MARCAS(state) {
      state.marcas = [];
    },
  },
};
