export default {
	namespaced: true,
	state: {
		companies: null,
		users: null,
		invites: null,
		vtex_accounts: null,
	},
	mutations: {
        RESET_COMPANIES(state) {
            state.companies = null;
        },
        SET_COMPANIES(state, data) {
            state.companies = data;
        },

        RESET_USERS(state) {
            state.users = null;
        },
        SET_USERS(state, data) {
            state.users = data;
        },

        RESET_INVITES(state) {
            state.invites = null;
        },
        SET_INVITES(state, data) {
            state.invites = data;
        },

        RESET_VTEX_ACCOUNTS(state) {
            state.vtex_accounts = null;
        },
        SET_VTEX_ACCOUNTS(state, data) {
            state.vtex_accounts = data;
        },
	}
};