import produce from "immer";

export default {
  namespaced: true,
  state: {
    users: [],
    usersEdited: true,
  },
  mutations: {
    SET_USERS_COMPANY_EDITED(state, edited) {
      state.usersEdited = edited;
    },
    SET_USERS_COMPANY(state, users) {
      state.users = users;
    },
    RESET_USERS_COMPANY(state) {
      state.users = [];
    },
    ADD_USERS_COMPANY(state, user) {
      state.users = [...state.users, user];
    },
    PUT_USERS_COMPANY(state, SetUser) {
      const Draft = produce(state.users, draft => {
        const UserIndex = draft.findIndex(user => user.user_id === SetUser.user_id);
        draft[UserIndex] = SetUser;
      });
      state.users = Draft;
    },
    DEL_USERS_COMPANY(state, id) {
      const Draft = produce(state.users, draft => {
        const UserIndex = draft.findIndex(user => user.user_id === id);
        if (UserIndex >= 0) {
          draft.splice(UserIndex, 1);
        }
      });
      state.users = Draft;
    },
  },
};
