export default {
  namespaced: true,
  state: {
    layout: "layout-claro",
    loading: false,
    menuAtivado: false,
    menu: false,
    previousPage: "",
    modal: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: "",
    },
  },
  mutations: {
    SET_TEMA(state, layout) {
      state.layout = layout;
    },
    SET_LOADING(state, valor) {
      state.loading = valor;
    },
    SET_SIDEBAR(state, valor) {
      state.menuAtivado = valor;
    },
    SET_MODAL(state, modal) {
      state.modal.modalAtivado = modal.ativado;
      state.modal.mensagemModal = modal.mensagem;
      state.modal.tipoModal = modal.tipo;
    },
    SET_MENU(state) {
      state.menu = !state.menu;
    },
    SET_PREVIOUS_PAGE(state, page) {
      state.previousPage = page;
    },
  },
};
