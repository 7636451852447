import client from "@/api";
import store from "@/store";
import router from "@/router";
// Caminhos
import {
  _rotaLogin,
  _rotaGetProfile,
  _rotaPreCadastro,
  _rotaCadastroCompleto,
  _rotaRecuperarSenha,
  _rotaVerificarConvite,
} from "@/api/_caminhosApi";

// Login
export function enviarLogin(data) {
  store.commit("interacoes/SET_LOADING", true);
  data.remember_me = true;
  client
    .post(`${_rotaLogin()}`, data)
    .then(resp => {
      store.commit("user/SET_LOGADO", true);
      store.commit("user/SET_DADOS_COMPLETOS", resp.data.user);
      window.localStorage.setItem("token", resp.data.token);
      store.state.interacoes.previousPage
        ? router.push({ path: store.state.interacoes.previousPage })
        : router.push({ path: "home" });
    })
    .catch(erro => {
      console.log("erro", erro.response);
      store.commit("user/SET_LOGADO", false);
      store.commit("user/SET_DADOS_COMPLETOS", false);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

// Recuperar Senha
export function recuperarSenha(email) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaRecuperarSenha()}/request`, {
      email: email,
    })
    .then(resp => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("recuperarSenha erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

// Enviar Nova Senha
export function enviarNovaSenha(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaRecuperarSenha()}/update`, data)
    .then(resp => {
      router.push({ path: "/login" });
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("enviarNovaSenha erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

// Busca o Token do Pré Cadastro
export function verificarPreCadastro(token) {
  store.commit("interacoes/SET_LOADING", true);
  return client
    .get(`${_rotaVerificarConvite()}/${token}`)
    .then(resp => {
      store.commit("interacoes/SET_LOADING", false);
      return resp.data;
    })
    .catch(erro => {
      console.log("verificarPreCadastro erro", erro);
      router.push({ path: "/login" });
      store.commit("interacoes/SET_LOADING", false);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Token inválido",
        tipo: "erro",
      });
    });
}

// Pré Cadastro
export function preCadastro(email, params) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaPreCadastro()}`, {
      account_email: email,
      source: "vdash.product.subscribe",
      aceite_lgpd: true,
      ...params,
    })
    .then(resp => {
      router.push({ path: "/login" });
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("preCadastroerro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

// Cadastro
export function cadastro(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaCadastroCompleto()}`, data)
    .then(() => {
      router.push({ path: "/login" });
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Cadastro realizado com sucesso.",
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("cadastro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

// Busca Perfil por Token
export function buscarPerfil() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaGetProfile()}`)
    .then(resp => {
      store.commit("user/SET_LOGADO", true);
      store.commit("user/SET_DADOS_COMPLETOS", resp.data.user);
      store.commit("user/SET_MANAGER_INTEGRATION", resp.data.user.manage_integrations);
      store.commit("user/SET_MANAGER_USER", resp.data.user.manage_users);
    })
    .catch(erro => {
      console.log("erro", erro.response);
      store.commit("user/SET_LOGADO", false);
      store.commit("user/SET_MANAGER_INTEGRATION", "");
      store.commit("user/SET_MANAGER_USER", "");
      store.commit("user/SET_DADOS_COMPLETOS", false);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function verificaSessao() {
  if (window.localStorage.getItem("token")) {
    store.commit("user/SET_LOGADO", true);
  }
}
