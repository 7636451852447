export default {
  namespaced: true,
  state: {
    csv: [],
  },
  mutations: {
    SET_CSV(state, csv) {
      state.csv = csv;
    },
  },
};
