<template>
  <div class="container-submenu">
    <div class="container-submenu__active" v-if="actualRoute">
      <div class="link-sidebar" v-if="actualRoute.meta.emptyIcon">
        <span class="link-icone"><IconMenu></IconMenu></span>
        <span class="link-texto">Menu</span>
      </div>
      <LinksSubmenu
        v-else
        :show="true"
        :texto="actualRoute.meta.menuInterno.texto"
        :link="actualRoute.path"
        :icone="actualRoute.meta.menuInterno.icone"
      />
      <span class="icon-down">
        <IcomDown></IcomDown>
      </span>
    </div>
    <nav class="container-submenu__items">
      <LinksSubmenu
        v-for="(link, i) in links"
        :show="!link.meta.menuInterno.onlyTitle"
        :key="i"
        :highlight="link.meta.menuInterno.highlight"
        :texto="link.meta.menuInterno.texto"
        :link="link.path"
        :icone="link.meta.menuInterno.icone"
      />
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LinksSubmenu from "@/components/submenu/LinksSubmenu.vue";
import IconMenu from "@/components/icones/IconMenu.vue";
import IcomDown from "@/components/icones/IcomDown.vue";
export default {
  data: () => ({
    actualRoute: null,
  }),
  components: {
    LinksSubmenu,
    IconMenu,
    IcomDown,
  },
  computed: {
    ...mapState({
      accounts: state => state.accounts.accountsPermissions,
      perfilUsuario: state => state.user.perfilUsuario,
    }),
    itemMenuInterno() {
      return this.$router.options.routes[0].children;
    },
    links() {
      return this.itemMenuInterno.filter(item => {
        const value = "/contas";
        if (this.accounts.length == 1) {
          return item.path !== value && item.meta.menuInterno !== false;
        }
         return item.meta.menuInterno !== false;
      });
    },
    currentRoute() {
      return this.$router.currentRoute;
    },
  },
  watch: {
    $route: function(data) {
      this.actualRoute = data;
    },
  },
  mounted() {
    this.actualRoute = this.currentRoute;
  },
};
</script>

<style lang="scss">
.container-submenu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  transition: 0.3s;
  min-width: 240px;
  &:hover {
    .container-submenu__items {
      display: block;
    }
  }
  &__active {
    align-items: center;
    border-right: 1px solid #e6e6e6;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    .link-sidebar {
      border-top: none;
    }
    .icon-down {
      svg {
        width: 40px;
      }
    }
    //  &:after {
    //    border: 5px solid $dark;
    //    border-bottom-color: transparent;
    //    border-left-color: transparent;
    //    border-right-color: transparent;
    //    content: "";
    //    display: block;
    //    height: 1px;
    //    position: absolute;
    //    right: 20px;
    //    top: 50%;
    //    width: 1px;
    //  }
  }
  &__items {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    display: none;
    height: auto;
    min-width: 240px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;
  }
}
</style>
