export default {
  namespaced: true,
  state: {
    ordersTotal: [],
    ordersBilling: [],
    coupons: [],
    coupons_last_page: 0,
    recurrent: [],
    ticketMedio: [],
    repurchase: [],
    ltv: [],
    list: [],
    list_last_page: 0,
    details: [],
  },
  mutations: {
    SET_ORDERS_TOTAL(state, data) {
      state.ordersTotal = data;
    },
    RESET_ORDERS_TOTAL(state) {
      state.ordersTotal = [];
    },

    SET_ORDERS_BILLING(state, data) {
      state.ordersBilling = data;
    },
    RESET_ORDERS_BILLING(state) {
      state.ordersBilling = [];
    },

    SET_COUPONS(state, data) {
      state.coupons = data;
      state.coupons_last_page = data.items && data.items.last_page ? data.items.last_page : state.coupons_last_page;
    },
    RESET_COUPONS(state) {
      state.coupons = [];
      state.coupons_last_page = 0;
    },

    SET_RECURRENT(state, data) {
      state.recurrent = data;
    },
    RESET_RECURRENT(state) {
      state.recurrent = [];
    },

    SET_TICKET_MEDIO(state, data) {
      state.ticketMedio = data;
    },
    RESET_TICKET_MEDIO(state) {
      state.ticketMedio = [];
    },

    SET_REPURCHASE(state, data) {
      state.repurchase = data;
    },
    RESET_REPURCHASE(state) {
      state.repurchase = [];
    },

    SET_LTV(state, data) {
      state.ltv = data;
    },
    RESET_LTV(state) {
      state.ltv = [];
    },

    SET_LIST(state, data) {
      state.list = data;
      state.list_last_page = data.data && data.last_page ? data.last_page : state.list_last_page;
    },
    RESET_LIST(state) {
      state.list = [];
      state.list_last_page = 0;
    },

    SET_DETAILS(state, data) {
      state.details = data;
    },
    RESET_DETAILS(state) {
      state.details = [];
    },
  },
};
