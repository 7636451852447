export default {
  namespaced: true,
  state: {
    orders: [],
    ordersStatuses: [],
    ordersDetails: [],
    ordersPayments: [],
    orders_last_page: 0,
  },
  mutations: {
    SET_ORDERS(state, data) {
      state.orders = data;
    },
    SET_ORDERS_DETAILS(state, data) {
      state.ordersDetails = data.items && data.items ? data.items : [];
      state.orders_last_page = data.items && data.items.last_page ? data.items.last_page : state.orders_last_page;
    },
    SET_ORDERS_STATUSES(state, data) {
      state.ordersStatuses = data;
    },
    SET_ORDERS_PAYMENTS(state, data) {
      state.ordersPayments = data;
    },
    RESET_ORDERS(state) {
      state.orders = [];
    },
  },
};
