export default {
  namespaced: true,
  state: {
    pedidosAll: [],
    pedidosHoje: [],
    pedidosOntem: [],
    pedidosMesAtual: [],
    compare: [],
    produtos: [],
    marcas: [],
    lastUpdate: null,
  },
  mutations: {
    RESET_PEDIDOS(state) {
      state.lastUpdate = [];
      state.pedidosAll = [];
      state.pedidosHoje = [];
      state.pedidosOntem = [];
      state.pedidosMesAtual = [];
      state.compare = [];
    },
    SET_PEDIDOS(state, data) {
      state.lastUpdate =
        data.atualizado_em && data.atualizado_em.created_at
          ? data.atualizado_em.created_at
          : null;
      state.pedidosAll =
        data.periodo && data.periodo.all ? data.periodo.all : [];
      state.pedidosHoje =
        data.periodo && data.periodo.hoje ? data.periodo.hoje : [];
      state.pedidosOntem =
        data.periodo && data.periodo.ontem ? data.periodo.ontem : [];
      state.pedidosMesAtual =
        data.periodo && data.periodo.mes_atual ? data.periodo.mes_atual : [];
      state.compare =
        data.compare ? data.compare : [];
    },
    RESET_PRODUTOS(state) {
      state.produtos = [];
    },
    SET_PRODUTOS(state, data) {
      state.produtos = data;
    },
    RESET_MARCAS(state) {
      state.marcas = [];
    },
    SET_MARCAS(state, data) {
      state.marcas = data;
    },
  },
};
