export default {
  namespaced: true,
  state: {
    plano: {},
    planoAtivo: {},
    planosDisponiveis: [],
    planosUnpaid: [],
    verifyTrial: {},
    selected: null,
    showModal: false,
    planosSelecionados: [],
    planosBonus: []
  },
  mutations: {
    SET_PLANO(state, plano) {
      state.plano = plano;
    },
    SET_PLANO_ATIVO(state, plano) {
      state.planoAtivo = plano;
    },
    SET_PLANO_DISPONIVEL(state, plano) {
      state.planosDisponiveis = plano;
    },
    SET_PLANOS_UNPAID(state, plano) {
      state.planosUnpaid = plano;
    },
    SET_VERIFY_TRIAL(state, plano) {
      state.verifyTrial = plano;
    },
    SET_SELECTED(state, data) {
      state.selected = data;
    },
    SET_MODAL(state, showModal) {
      state.showModal = showModal;
    },
    SET_PLANOS_SELECIONADOS(state, data) {
      state.planosSelecionados = data;
    },
    SET_PLANOS_BONUS(state, data) {
      state.planosBonus = data;
    },
  },
};
