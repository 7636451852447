import client from "@/api";
import store from "@/store";
import {
  _rotaGetAccounts,
  _rotaGetCsvAccounts,
  _rotaGetAccountsPermissions,
  _rotaGetAccountsLinked,
  _rotaSendInvitation,
  _rotaGetInvitationPending,
  _rotaDeleteInvitation,
  _rotaValidationTokenInvitation,
  _rotaAcceptInvite,
  _rotaSetAccountActive,
  _rotaDeleteAccount,
} from "@/api/_caminhosApi";
import router from "../../router";

export function getAccountsPermissions() {
  // store.commit("interacoes/SET_LOADING", true);
  store.commit("accounts/RESET_ACCOUNTS_PERMISSIONS", null);
  client
    .get(_rotaGetAccountsPermissions())
    .then(resp => {
      store.commit("accounts/SET_ACCOUNTS_PERMISSIONS", resp.data);
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function setAccountActive(accountId) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("accounts/RESET_ACCOUNT_ACTIVE", null);
  client
    .post(_rotaSetAccountActive(accountId))
    .then(resp => {
      if (resp.status === 200) {
        store.commit("accounts/SET_ACCOUNT_ACTIVE", resp.data);
        getAccountsPermissions();
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function deleteAccount(id) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .delete(_rotaDeleteAccount(id))
    .then(resp => {
      getAccountsPermissions();
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getAccounts(from, to, qtd, page) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("accounts/RESET_ACCOUNTS", null);
  client
    .get(_rotaGetAccounts(), {
      params: {
        from: from,
        to: to,
        per_page: qtd,
        page: page,
      },
    })
    .then(resp => {
      if (resp.status === 200) {
        store.commit("accounts/SET_ACCOUNTS", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getCSV(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetCsvAccounts(), {
      params: {
        from,
        to,
      },
      responseType: "blob",
    })
    .then(resp => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "accounts.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getAccountsLinked() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetAccountsLinked())
    .then(resp => {
      store.commit("accounts/SET_ACCOUNTS_LINKED", resp.data);
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function sendInvitation(email) {
  const data = {
    email: email,
  };
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(_rotaSendInvitation(), data)
    .then(resp => {
      getInvitationPending();
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function deleteInvitation(id) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .delete(_rotaDeleteInvitation(id))
    .then(resp => {
      getInvitationPending();
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getInvitationPending() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetInvitationPending())
    .then(resp => {
      store.commit("accounts/SET_INVITATION_PENDING", resp.data);
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function getValidationInvitation(token) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaValidationTokenInvitation(token))
    .then(resp => {
      store.commit("accounts/SET_INVITATION_VALIDATION", resp.data);
    })
    .catch(erro => {
      router.push("/");
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function acceptInvite(token) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(_rotaAcceptInvite(token))
    .then(() => {
      store.commit("accounts/SET_INVITATION_ACCEPT", true);
      store.commit("accounts/SET_SHOW_ACCOUNTS", true);
      getAccountsPermissions()
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
