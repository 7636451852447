<template>
  <div id="main" :class="layout">
    <transition name="fade">
      <Loading v-if="loading" />
    </transition>
    <MensagemAlerta />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Loading from "@/components/loading/Loading.vue";
const MensagemAlerta = () => import("@/components/modal/MensagemAlerta.vue");
export default {
  components: {
    Loading,
    MensagemAlerta,
  },
  computed: {
    layout() {
      return this.$store.state.interacoes.layout;
    },
    loading() {
      return this.$store.state.interacoes.loading;
    },
    modal() {
      return this.$store.state.interacoes.modal;
    },
  },
};
</script>
